.fc .fc-button-primary {
  background-color: var(--primary);
  border: none;
}

.fc .fc-button-primary > span {
  color: #fff;
}

.fc .fc-button-primary:hover {
  background-color: var(--primary);
  opacity: 0.8;
}

.fc-event-title-container {
  background-color: var(--primary);
  cursor: pointer;
}

.fc-event-title-container .fc-event-title {
  color: #fff;
}
